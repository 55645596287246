import React, {  } from 'react'
import { Career as CareerSections, CareerDetail as CareerDetailSections } from "src/assembled"
import { Footer } from 'src/components'
import { SEO, useIsDeviceWidth } from 'src/components/utils'


export default function CareerPage({ location }) {
    const isSmallDevice = useIsDeviceWidth({ from: 0, to: 800 })


    // seo
    const seo = <SEO
    title="Career"
    description="디어젠과 함께하실 분들을 찾고있습니다. 어서 로켓에 올라타세요!" 
    pathname={location.pathname} />


    // render
    const render = <main role="main">
        <CareerSections />
        {isSmallDevice && <CareerDetailSections />}
    </main>


    return <>
        {seo}
        {render}
        <Footer location={location} />
    </>
}
